import React, { useEffect, useState, useRef } from "react";
import { TYPECHAT } from "../../../config/constants/constants";
import { MessagesProps } from "../../Chatbot/type";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import BotIcon from "../../../assets/imgs/chatbit-new.svg";
import VolumenLowIcon from "../../../assets/imgs/volume-low.svg";
import RepeatIcon from "../../../assets/imgs/repeat.svg";
import ClipBoardIcon from "../../../assets/imgs/clipboard-text.svg";
import LikeIcon from "../../../assets/imgs/like.svg";
import DisLikeIcon from "../../../assets/imgs/dislike.svg";
import LikeedIcon from "../../../assets/imgs/liked-color-icon.svg";
import DisLikedIcon from "../../../assets/imgs/disliked-color-icon.svg";
import LoadingAnimation from "./LoadingAnimation";
import TypingAnimation from "./TypingAnimation";
import TableComponent from "./tableComponent";
import { getInitials } from "../../../lib/utils";
import { TABLE_ROUTES } from "../../../config/constants/constants";

export const Messages: React.FC<MessagesProps> = ({
  chats,
  onHandleMarkFeedBackLike,
  onHandleMarkFeedBackDisLike,
  isActivateAnimation,
  setIsActivateAnimation,
  userName,
}) => {
  const [copiedDescription, setCopiedDescription] = useState("");
  const [checkId, setCheckId] = useState<string | null>(null);
  const [lastChatId, setLastChatId] = useState<string | null>("");

  const messagesStartRef = useRef<HTMLDivElement>(null);

  const copyToClipboard = (description: string | null) => {
    if (description) {
      navigator.clipboard
        .writeText(description)
        .then(() => {
          setCopiedDescription(description);
        })
        .catch((error) => console.error("Failed to copy description:", error));
    }
  };

  useEffect(() => {
    if (messagesStartRef.current) {
      messagesStartRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollBy(0, -50);
    }

  }, [chats]);

  function showTable(str: string | null) {
    setCheckId(str);
    if (str === null) {
      setLastChatId(chats[chats.length - 1]?.detail_chat_id);
    }
  }

  const handleInactivateAnimation = () => {
    console.log("text");
  };

  function isJsonString(str: string | null): object | false {
    if (str === null) {
      return false;
    }
    try {
      return JSON.parse(str);
    } catch (e) {
      return false;
    }
  }

  return (
    <div className="mx-5">
      <div ref={messagesStartRef}></div>
      {chats.map((chat, index) => {

        const parsedDescription = isJsonString(chat.description);

        return (
          <div key={index}>
            {/* user question section */}
            {chat.type === TYPECHAT.user && (
              <div className="flex mt-5">
                <div
                  id="user-chat-info"
                  className="mr-4 flex h-9 w-9 items-center justify-center rounded-full"
                  style={{ backgroundColor: Colors.backgroundColorUser }}
                >
                  <Paragraph
                    text={`${getInitials(userName)}`}
                    props={{ fontSize: "12px" }}
                    ParagraphType="PragSemBold600"
                    color="textPrimary"
                  />
                </div>
                <div id="chat-description-answer" className="flex flex-1">
                  <Paragraph
                    text={chat.description ? chat.description : ""}
                    props={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "155%",
                    }}
                    ParagraphType="PragraghSemiBold600"
                    color="textLigth"
                  />
                </div>
              </div>
            )}
            {/* animation question */}
            {chat.type === TYPECHAT.ai_question && (
              <div className="flex mt-5">
                <div
                  id="user-chat-info"
                  className="mr-4 flex h-9 w-9 items-center justify-center rounded-full"
                  style={{ backgroundColor: Colors.backgroundPrimary }}
                >
                  <img src={BotIcon} alt="" />
                </div>
                <div className="flex">
                  <LoadingAnimation />
                </div>
              </div>
            )}

            {/* Ai answer */}
            {chat.type === TYPECHAT.ai_answer && (
              <div className="flex mt-5">
                <div
                  id="user-chat-info"
                  className="mr-4 flex h-9 w-9 items-center justify-center rounded-full"
                  style={{ backgroundColor: Colors.backgroundPrimary }}
                >
                  <img src={BotIcon} alt="" />
                </div>
                <div
                  id="chat-description-answer"
                  className="flex flex-col flex-1"
                >

                  <TypingAnimation
                    text={parsedDescription ? (parsedDescription as { answer: string }).answer : chat.description || ""}
                    index={index}
                    isActivateAnimation={
                      isActivateAnimation
                        ? index === chats.length - 1 && isActivateAnimation
                        : false
                    }
                    setIsActivateAnimation={setIsActivateAnimation}
                    onInactivateAnimation={handleInactivateAnimation}
                    id={chat.detail_chat_id ? chat.detail_chat_id : ""}
                    setId={showTable}
                  />
                  {parsedDescription && checkId !== chat.detail_chat_id && (!isActivateAnimation || (index !== chats.length - 1) || (lastChatId === chat.detail_chat_id)) &&
                    <TableComponent
                      headers={(parsedDescription as { header: string[] }).header}
                      rows={(parsedDescription as { rows: object[] }).rows}
                    />
                  }
                  {TABLE_ROUTES.includes(chat?.route || "") &&
                    checkId !== chat.detail_chat_id && (!isActivateAnimation || (index !== chats.length - 1) || (lastChatId === chat.detail_chat_id)) && (
                      <TableComponent
                        headers={chat?.jsonTable?.headers}
                        rows={chat?.jsonTable?.rows}
                      />
                    )}
                  {checkId !== chat.detail_chat_id &&
                    chat.description !== "Error. Please try again." && (
                      <div
                        id="tool-bar"
                        className="flex px-[10px] py-2 mt-2 gap-[10px] bg-opacity-10 bg-white rounded-full max-w-[66px]"
                      >
                        {/* <img src={RepeatIcon} alt="" className="w-[18px]" />
                      <div onClick={() => copyToClipboard(chat.description)}>
                        <img src={ClipBoardIcon} alt="" className="w-[18px]" />
                      </div> */}
                        <button
                          onClick={() =>
                            onHandleMarkFeedBackLike(
                              chat.uuid,
                              chat.detail_chat_id,
                              chat.feedback_like,
                            )
                          }
                          className="p-0"
                        >
                          <img
                            src={
                              chat.feedback_like === "true"
                                ? LikeedIcon
                                : LikeIcon
                            }
                            alt="Like"
                            className="w-[18px]"
                          />
                        </button>
                        <button
                          onClick={() =>
                            onHandleMarkFeedBackDisLike(
                              chat.uuid,
                              chat.detail_chat_id,
                              chat.feedback_like,
                            )
                          }
                          className="p-0"
                        >
                          <img
                            src={
                              chat.feedback_like === "false"
                                ? DisLikedIcon
                                : DisLikeIcon
                            }
                            alt=""
                            className="w-[18px]"
                          />
                        </button>
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  );
};
