import { Paragraph } from "../../Commons/Paragraph";
import { DataItem } from "../../../Controllers/TradeAnalyzer/type";
import HeaderReport from "../../../assets/imgs/header-trade-report.png";
import NullPlayerImage from "../../../assets/imgs/null-player-img.svg";
import { teamColors } from "../../../config/constants/teamColors";
import { Button } from "../../ui/button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useFirebaseAnalytics from "../../../hooks/useFirabaseAnality";
import { Colors } from "../../Commons/Colors/Colors";
import BotIcon from "../../../assets/imgs/chatbit-new.svg";
import TypingParaAnimation from "../../Commons/TypingParaAnimation/TypingParaAnimation";
import LottieAnimation from "../../Commons/LottieAnimation/LottieAnimation";
import chatbotAnimation from "../../../assets/animations/Chatbot.json";

interface Props {
  playersDataTradeAway: DataItem[];
  playersDataTradeFor: DataItem[];
  report?: string | null;
  tradeOutcome?: string | null;
  onReset: () => void;
}

const TradeReport: React.FC<Props> = ({
  playersDataTradeAway,
  playersDataTradeFor,
  report,
  tradeOutcome,
  onReset,
}) => {
  const navigate = useNavigate();
  const { logEventAnalytics } = useFirebaseAnalytics();

  const [titleResult, setTitleResult] = useState<string>("");
  const [subTitleResult, setSubTitleResult] = useState<string>("");

  useEffect(() => {
    switch (tradeOutcome) {
      case "Favors opponent":
        setTitleResult("The Opponent");
        setSubTitleResult("is favored in this trade");
        break;
      case "Favors you":
        setTitleResult("Your Team");
        setSubTitleResult("is favored in this trade");
        break;
      case "Even trade":
      default:
        setTitleResult("Toss-Up");
        setSubTitleResult("This trade is perfectly balanced");
        break;
    }
  }, [tradeOutcome]);
  
  const handleNavigateHome = () => {
    logEventAnalytics("trade_complete_home", {
      name: "Trade Analyzer Complete Go Home",
    });
      navigate("/home");    
  };

  const renderPlayerImages = (playersData: DataItem[], isTradeFor: boolean) => (
    <div className={`flex space-x-[-10px] w-[40%] max-w-[40%]  ${isTradeFor ? "z-order-desc" : "justify-end"}`}>
      {playersData.map((item, index) => (
        <div
          key={item.roto_player_id}
          className="w-[55px] h-[55px] rounded-full flex items-end overflow-hidden"
          style={{
            backgroundColor: item.playerTeamShort
              ? teamColors[item.playerTeamShort]
              : "#4F2683",
          }}
        >
          <img
            className="scale-[1.7] rounded-full"
            src={item.playerHeadshotURL || NullPlayerImage}
            alt=""
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="absolute top-0 w-full flex h-screen flex-col">
      <div
        className="bg-cover bg-center w-full h-72 rounded-b-[12px] "
        style={{ backgroundImage: `url(${HeaderReport})` }}
      >
        <div className={`flex justify-center p-4 pt-14 ${!tradeOutcome && "pb-8"}`}>
          <Paragraph
            text="Trade Report"
            props={{ fontSize: "22px", fontWeight: 500 }}
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
        </div>
        {tradeOutcome && (
          <>

            <div className="flex justify-center">
              <Paragraph
                text={titleResult}
                props={{ fontSize: "40px", fontWeight: 600 }}
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
            </div>
            <div className="flex justify-center">
              <Paragraph
                text={subTitleResult}
                props={{ fontSize: "16px", fontWeight: 400 }}
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
            </div>
          </>
        )}
        <div className="flex justify-center items-center gap-5 pt-4">
          {renderPlayerImages(playersDataTradeAway, false)}
          <div className="text-white text-[20px] font-bold">FOR</div>
          {renderPlayerImages(playersDataTradeFor, true)}
        </div>
      </div>

      <div className=" flex pl-4 pt-4 ">
        <div
          className=" mr-2 flex h-9 w-9 items-center justify-center rounded-full"
          style={{ backgroundColor: Colors.backgroundPrimary }}
        >
          <LottieAnimation animationData={chatbotAnimation} />
        </div>
        <div
          id="chat-description-answer"
          className="flex flex-col flex-1 justify-center"
        >
          <Paragraph
              text={"RotoBot’s Take"}
              props={{ fontSize: "14px", fontWeight: 700 }}
              ParagraphType="Paragraph22"
              color="textPrimary"
            />
        </div>
      </div>
      <div className="overflow-y-auto max-h-[55vh] px-9 py-4">
        {report &&
        <TypingParaAnimation strings={[report]} typingSpeed={10} />}
      </div>
      <div className="justify-center w-full flex fixed bottom-0 mb-8 gap-6">
        <Button
          className="w-[40%] h-[50px] text-xs bg-[#FFFFFF1A] inset-0 backdrop-blur"
          onClick={onReset}
        >
          Analyze Another
        </Button>
        <Button
          className="w-[40%] h-[50px] text-xs"
          onClick={handleNavigateHome}
        >
          Go Home
        </Button>
      </div>
    </div>
  );
};

export default TradeReport;
