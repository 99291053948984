import { DataItem } from "../../../Controllers/TradeAnalyzer/type";
import ArrowBackIcon from "../../.././assets/imgs/arrow-back-left.svg";
import { Colors } from "../../Commons/Colors/Colors";
import { positions } from "../../Rankings/data";
import { Paragraph } from "../Paragraph";
import TablePlayers from "./TablePlayers/TablePlayers";
import TableTabs, { TabItem } from "../../Commons/TableTabs/TableTabs";
import { useEffect, useState } from "react";
import { Button } from "../../ui/button";

interface SelectPlayersProps {
  title: string;
  subTitle: string;
  selectedPlayersData: DataItem[];
  playersData: DataItem[];
  handleButtonBack: () => void;
  removePlayer: (roto_player_id: string | undefined) => void;
  handleFilterSearch: (search: string) => void;
  handleSelectPosition: (item: PositionItem) => void;
  handleSelectPlayer: (item: DataItem) => void;
  handleButtonNext: () => void;
  search: string;
  minPlayers: number;
  maxPlayers: number;
  textButton: string;
  credit?: string;
  InicialselectedTab: TabItem;
}

type PositionItem = {
  id: number;
  name: string;
};

const SelectPlayers: React.FC<SelectPlayersProps> = ({
  title,
  subTitle,
  selectedPlayersData,
  playersData,
  handleButtonBack,
  removePlayer,
  handleFilterSearch,
  handleSelectPosition,
  handleSelectPlayer,
  handleButtonNext,
  search,
  minPlayers,
  maxPlayers,
  textButton,
  credit = "",
  InicialselectedTab
}) => {
  const [disabledAdd, setDisabledAdd] = useState<boolean>(false);

  useEffect(() => {
    setDisabledAdd(selectedPlayersData.length >= maxPlayers);

  }, [maxPlayers, selectedPlayersData]);

  return (
    <div className="flex h-full relative -m-5">
      <div className="w-full">
        <div className="flex-1 pb-5">
          <div className="p-5">
            <div className="flex items-center justify-between">
              <div
                className="cursor-pointer rounded-full w-14 h-14 flex justify-center items-center grayBtn"
                onClick={() => handleButtonBack()}
              >
                <img src={ArrowBackIcon} alt="" />
              </div>
              <Paragraph
                text={title}
                props={{ fontSize: "22px", marginLeft: "-45px" }}
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
              <div></div>
            </div>
            <div className="flex justify-center">
              <Paragraph
                text={subTitle}
                props={{ fontSize: "14px", opacity: "0.75" }}
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
            </div>
          </div>

          {selectedPlayersData.length > 0 && (
            <TablePlayers
              tableHeader={["Selected Players"]}
              data={selectedPlayersData}
              selected={true}
              disabled={false}
              onSelectPlayer={(player: DataItem) => {
                removePlayer(player?.roto_player_id);
              }}
            />
          )}

          <div className="p-5 flex flex-col">
            <div className="flex flex-col justify-center">
              <div className="relative flex items-center mr-2">
                <span className="absolute left-3 text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.9 14.32a8 8 0 111.42-1.42l4.31 4.3a1 1 0 01-1.42 1.42l-4.3-4.31zM8 14a6 6 0 100-12 6 6 0 000 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  placeholder="Search for a player..."
                  value={search ? search : ""}
                  onChange={(e) => handleFilterSearch(e.target.value)}
                  className={`text-white pl-10 py-2 rounded-[50px] border border-[${Colors.backgroundSecundary}] focus:outline-none bg-[${Colors.backgroundSecundary}]`}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>

          <div className="pl-5 overflow-x-auto no-scrollbar">
            <TableTabs
              tabItems={positions}
              tabCustomStyle="w-15.5 h-11 shrink-0"
              onTabSelect={handleSelectPosition}
              InicialselectedTab={InicialselectedTab}
            />
          </div>
          <div className="mt-3">
            <TablePlayers
              tableHeader={["Players"]}
              data={playersData}
              selected={false}
              disabled={disabledAdd}
              credit={credit}
              onSelectPlayer={(player: DataItem) => {
                handleSelectPlayer(player);
              }}
            />
          </div>
        </div>
        {selectedPlayersData.length >= minPlayers && (
          <Button
            className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-[90%] h-[60px] text-base mb-6"
            onClick={() => handleButtonNext()}
          >
            {textButton}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SelectPlayers;
