
import HeaderReport from "../../../assets/imgs/header-out-of-credit.svg";
import OutOfCreditBg from "../../../assets/imgs/out-of-credit-bg.svg";
import { Button } from "../../ui/button";
import { useNavigate } from "react-router-dom";
import { Paragraph } from "../Paragraph";
import ArrowBackIcon from "../../.././assets/imgs/arrow-back-left.svg";
import LottieAnimation from "../../Commons/LottieAnimation/LottieAnimation";
import onBoardingAnimation1 from "../../../assets/animations/on-boarding-animation-1.json";
import onBoardingAnimation2 from "../../../assets/animations/on-boarding-animation-2.json";
import onBoardingAnimation3 from "../../../assets/animations/on-boarding-animation-3.json";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { hapticsImpactLight } from "../../../lib/utils";


const OutOfCredits: React.FC = () => {
  const navigate = useNavigate();
  const handleNavigatepaywall = () => {
    navigate("/chatbot-paywall");
  };

  const handleButtonBack = () => {
    hapticsImpactLight();
    navigate("/home");

  };

  const [currentTab, setCurrentTab] = useState<number>(0);

  const sliderData = [
    {
      id: "0",
      title: "Meet Rotobot AI",
      description:
        "Your 24/7 AI Fantasy Guru. Fully equipped to tackle all your fantasy football needs.",
      animation: onBoardingAnimation1,
    },
    {
      id: "1",
      title: "Tap Into Insights",
      description:
        "AI-Powered Data Discovery to make your fantasy decisions a breeze.",
      animation: onBoardingAnimation2,
    },
    {
      id: "2",
      title: "Victory Awaits",
      description: "Experience the easiest way to elevate your fantasy game.",
      animation: onBoardingAnimation3,
    },
  ];

  return (
    <div className="absolute top-0 left-0 w-full flex h-screen flex-col"
      style={{
        backgroundImage: `url(${OutOfCreditBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}>
      <div
        className=" w-full h-[530px] p-5 bg-cover rounded-b-[12px] "
        style={{
          backgroundImage: `url(${HeaderReport})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <div
          className="cursor-pointer  rounded-full w-14 h-14 flex justify-center items-center grayBtn"
          onClick={() => handleButtonBack()}
        >
          <img src={ArrowBackIcon} alt="" />
        </div>
        <div></div>
        <div></div>
        <AnimatePresence>
          <motion.div
            className="h-[81%] mt-[-40px]"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.7, ease: "easeInOut" }}
          >
            <LottieAnimation
              animationData={sliderData[currentTab].animation}
            />
          </motion.div>
        </AnimatePresence>
      </div>

      <div className=" flex pl-4 pt-4 ">

        <div
          id="chat-description-answer"
          className="flex flex-col flex-1 justify-center items-center"
        >
          <Paragraph
            text={"You’ve Run "}
            props={{ fontSize: "40px", fontWeight: 700 }}
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
          <Paragraph
            text={"Out of Credits"}
            props={{ fontSize: "40px", fontWeight: 700, lineHeight: "120%" }}
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
          <Paragraph
            text={"Subscribe now for unlimited questions"}
            props={{ fontSize: "14px", fontWeight: 600, opacity: "0.8" }}
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
        </div>
      </div>

      <div className="justify-center w-full flex fixed bottom-0 mb-16 gap-6">
        <Button
          className="w-[266px] h-[60px] text-[14px] font-semibold"
          onClick={handleNavigatepaywall}
        >
          Go Unlimited
        </Button>
      </div>
    </div>
  );
};

export default OutOfCredits;
