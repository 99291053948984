import { grid } from "ldrs";
import { Paragraph } from "../../Commons/Paragraph";

grid.register();

const GridProgressBar = () => {
  return (
    <div className="flex justify-center flex-col absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="p-6">
        <l-grid size="75" speed="1.5" color="#fb00ff"></l-grid>
      </div>
      <Paragraph
        text={"Calculating"}
        props={{ fontSize: "20px", fontWeight: "600" }}
        ParagraphType="Paragraph22"
        color="textPrimary"
      />
    </div>
  );
};

export default GridProgressBar;
