import React from "react";
import { Layout } from "../Layouts/Layout";
import SettingItem from "../Commons/SettingItem/SettingItem";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import premium_icon from "../../assets/imgs/premium.svg";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { useNavigate } from "react-router";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import { hapticsImpactLight } from "../../lib/utils";

const Subscription = () => {
    
  const navigate = useNavigate();

  const navigateBack = () => {
    hapticsImpactLight();
    navigate("/settings");
  };
  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center grayBtn"
          onClick={navigateBack}
        >
          <img src={ArrowBackIcon} alt="" />
        </div>
        <Paragraph
          text="Subscription"
          props={{ fontSize: "28px", fontWeight: 600, marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>

      <div className="mt-5">
        <Paragraph
          text="Membership"
          props={{
            fontSize: "18px",
            fontWeight: 600,
            letterSpacing: "-0.5px",
            lineHeight: "14px",
            marginBottom: "16px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div
          className="max-w-sm mx-auto text-white rounded-[18px] px-[14px] py-4"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        >
          <SettingItem
            icon={premium_icon}
            label="Premium User"
            lastItem
            arrow={false}
            action={() => navigate("/membership")}
            customStyle="py-0"
          />
        </div>
      </div>

      <PaymentMethod/>

    </Layout>
  );
};

export default Subscription;
