import { useEffect, useState } from "react";
import { Layout } from "../Layouts/Layout";
import { useNavigate } from "react-router-dom";
import { getROSRankings } from "../../Controllers/PlayerRanking";

import DecideReport from "./DecideReport/DecideReport";
import { DataItem } from "../../Controllers/TradeAnalyzer/type";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import useFirebaseAnalytics from "../../hooks/useFirabaseAnality";
import { motion, AnimatePresence } from "framer-motion";
import SelectPlayers from "../Commons/SelectPlayers/SelectPlayers";
import GridProgressBar from "./GridProgressBar/GridProgressBar";
import PlayersToStart from "./PlayersToStart/PlayersToStart";
import OutOfCredits from "../Commons/OutOfCredits/OutOfCredits";
import { getSitStartAnalyzer } from "../../Controllers/SitStartAnalyzer/SitStartAnalyzer";
import { isMobile } from "react-device-detect";
import { useApplePaymentAuth } from "../../Context/AppleContext/AppleContext";

type PositionItem = {
  id: number;
  name: string;
};

const SitStartDecider = () => {
  const navigate = useNavigate();
  const { logEventAnalytics } = useFirebaseAnalytics();

  const [playersData, setPlayersData] = useState<DataItem[]>([]);
  const [playersDataSit, setPlayersDataSit] = useState<DataItem[]>([]);
  const [playersDataStart, setPlayersDataStart] = useState<DataItem[]>([]);
  const [deciderScreen, setDeciderScreen] = useState<string>("SelectPlayers");
  //const [playersToStart, setPlayersToStart] = useState<number>(0);
  const [creditRemaing, setCreditRemaing] = useState<number>(3);

  const [selectplayersData, setSelectPlayersData] = useState<DataItem[]>(
    [],
  );

  const [positionSelected, setPosicionSelected] = useState<PositionItem>({
    id: 1,
    name: "All",
  });
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>();
  const [report, setReport] = useState<string | null | undefined>("");

  const { getUserData, getProfileForDetails } = useSupabaseAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const { platform } = useApplePaymentAuth();

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }

    getCreditRemaing(response?.data?.user?.id);
  };

  const getCreditRemaing = async (user_id: any) =>{
    const profile = await getProfileForDetails(user_id);
    
    setCreditRemaing(profile.feature_credits);

    if(profile.feature_credits == 0 && !profile.is_super_user){
      setDeciderScreen("OutOfCredits");
    }
  }

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchaPlayerData = async () => {
    const rosRankings = await getROSRankings();
    if (!rosRankings?.data) return;
    let filteredPlayers = rosRankings?.data;

    filteredPlayers = filteredPlayers
      .filter(
        (player: DataItem) =>
          !selectplayersData.some(
            (selectplayer) =>
                selectplayer.roto_player_id === player.roto_player_id,
          ),
      );

    if (searchTerm) {
      filteredPlayers = filteredPlayers.filter(
        (player: any) =>
          player.playerName.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (positionSelected.name === "All" ||
            player.playerPosition === positionSelected.name),
      );
    } else if (positionSelected.name !== "All") {
      filteredPlayers = filteredPlayers.filter(
        (player: any) => player.playerPosition === positionSelected.name,
      );
    }

    setPlayersData(filteredPlayers);
  };

  useEffect(() => {
    fetchaPlayerData();
  }, [positionSelected, searchTerm, selectplayersData]);

  const handleSelectPosition = (item: PositionItem) => {
    setPosicionSelected(item);
  };

  const handleFilterSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleSelectPlayer = (newPlayer: DataItem) => {
      logEventAnalytics("sit_start_player_selected", {
        name: "sit/start Player Selected",
        playerId: newPlayer.roto_player_id,
      });
      setSelectPlayersData((prevPlayers) => [...prevPlayers, newPlayer]);
      setSearchTerm("");
      setPosicionSelected({
        id: 1,
        name: "All",
      });
      
  };

  const removePlayer = (idToRemove: string | undefined) => {
    setSelectPlayersData((prevPlayers) =>
      prevPlayers.filter((player) => player.roto_player_id !== idToRemove),
    );
  };

  const handleButtonNext = () => {
    logEventAnalytics("sit_start_nav_next", {
      name: "sit/start Analyzer Next",
    });
    if(selectplayersData.length == 2){
        handleButtonCalculate(1);
    }
    else {
        setDeciderScreen("playersToStart");
    }
  };

  const handleButtonBack = () => {
    if (deciderScreen === "SelectPlayers") {
      navigate("/home");
    } else {
        setDeciderScreen("SelectPlayers");
    }
  };

  const handleButtonCalculate = async (playerNumber: number) => {
    logEventAnalytics("sit_start_calculate", {
      name: "sit/start Analyzer Decide Analyzed",
    });
    //calculate
    const userId = currentUser?.id;
    setDeciderScreen("Calculating");
    const sitStartData = await getSitStartAnalyzer(
       selectplayersData,
       playerNumber,
       userId,
       platform
    );
  

    switch (sitStartData.status) {
      case 200:
        {
          setDeciderScreen("Report");
          setReport(sitStartData.data?.detail_chat?.ai_response);
          const filteredPlayersStart = selectplayersData.filter(player => sitStartData.data?.players_to_start.includes(player.playerName));
          const filteredPlayersSit = selectplayersData.filter(player => sitStartData.data?.players_to_sit.includes(player.playerName));
          setPlayersDataStart(filteredPlayersStart);
          setPlayersDataSit(filteredPlayersSit);
        }
        break;
      case 402:
        if (isMobile && platform && platform === "ios") {
          setDeciderScreen("OutOfCredits");
        } else {
          navigate("/home");
        }
        break;
      default:
        setDeciderScreen("Report");
        setReport("Error has occurred, please try again");
        setPlayersDataStart([]);
        setPlayersDataSit([]);
        break;
    }

  };

  const handleOnReset = () => {
    logEventAnalytics("sit_start_complete_again", {
      name: " sit/start Analyzer Complete New Query",
    });
    setSelectPlayersData([]);
    setSearchTerm("");
    setDeciderScreen("SelectPlayers");

    const userId = currentUser?.id;
    getCreditRemaing(userId);
  };

  return (
    <>
      <Layout>
        {deciderScreen === "SelectPlayers" && (
          <SelectPlayers
            title="Sit/Start Decider"
            subTitle="Choose players to consider"
            selectedPlayersData={selectplayersData}
            playersData={playersData}
            handleButtonBack={handleButtonBack}
            removePlayer={removePlayer}
            handleFilterSearch={handleFilterSearch}
            handleSelectPosition={handleSelectPosition}
            handleSelectPlayer={handleSelectPlayer}
            handleButtonNext={handleButtonNext}
            search={searchTerm || ""}
            minPlayers={2}
            maxPlayers={5}
            textButton="Next"
            credit={`Credits Remaining: ${creditRemaing}`}
            InicialselectedTab={positionSelected}
          ></SelectPlayers>
        )}
        {deciderScreen === "playersToStart" && 
            <PlayersToStart
            totalplayers={selectplayersData.length -1}
            onUseACredit={handleButtonCalculate}
            onGoback={handleButtonBack}
            />
            
            }
        {deciderScreen === "Calculating" && <GridProgressBar />}
        {deciderScreen === "OutOfCredits" && <OutOfCredits />}
      </Layout>
      <AnimatePresence>
        {deciderScreen === "Report" && (
          <motion.div
            className="fixed top-0 right-0 w-full z-30 h-screen overflow-y-auto capabilities-bg"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.7, ease: "easeInOut" }}
          >
            <DecideReport
              playersDataStart={playersDataStart}
              playersDataSit={playersDataSit}
              report={report}
              onReset={handleOnReset}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SitStartDecider;
