import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Paragraph } from "../Commons/Paragraph";
import { Button } from "../ui/button";
import LottieAnimation from "../Commons/LottieAnimation/LottieAnimation";
import chatbotAnimation from "../../assets/animations/Chatbot.json";
import pop_up_bg from "../../assets/imgs/score-pop-up-bg.png";

interface ScoreModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const ScoreModal: React.FC<ScoreModalProps> = ({ isVisible, onClose }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-[1000] flex justify-center"
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          onClick={onClose}
        >
          <motion.div
            className="text-white flex justify-center items-end h-full max-w-[500px]"
            initial={{ translateY: "100%" }}
            animate={{ translateY: 0 }}
            exit={{ translateY: "100%" }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.15)",
              }}
              className="text-center backdrop-blur-[25px] transition-all duration-500 rounded-t-[20px]"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="relative">
                <img
                  src={pop_up_bg}
                  alt="Popup Background"
                  className="rounded-t-[20px] w-full"
                />
                <div className="rounded-full w-[100.92px] h-[100.92px] sm:hidden mb-6 mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <LottieAnimation animationData={chatbotAnimation} />
                </div>
              </div>
              <div className="p-4 bg-[#212130]">
                <Paragraph
                  text="What is RotoBot Score?"
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                  className="mb-1.5 text-3xl !font-semibold mt-5"
                />
                <Paragraph
                  text="The RotoBot Score is our custom metric that combines player stats, usage, and team context to give you a quick, clear snapshot of a player’s fantasy value. Use it to make smarter decisions on who to start, trade, or pick up."
                  props={{
                    fontSize: "15px",
                    fontWeight: "400",
                    opacity: "0.70",
                  }}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                  className="mt-6"
                />
                <Button
                  className="text-base font-medium mt-10 mb-3 rounded-[40.266px] w-[287.502px]"
                  onClick={onClose}
                >
                  Got it
                </Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
      {isVisible && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-20"
          style={{
            background: "rgba(24, 26, 32, 0.50)",
            backdropFilter: "blur(9px)",
            WebkitBackdropFilter: "blur(9px)",
          }}
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
        ></motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScoreModal;
