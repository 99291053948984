
import HttpRequestChat from "../../Services/HttpRequestChat";
import { DataItem } from "../TradeAnalyzer/type";


export const getSitStartAnalyzer = async (
  players: DataItem[],
  num_to_start: number,
  user_email: string | null = null,
  platform: string | null | undefined,
) => {
  try {

      const response = await HttpRequestChat({
        method: "POST",
        url: "/v1/sit_start_analyzer?platform=" + platform,
        data: {
          players,
          num_to_start,
          user_email: user_email,
        },
      });
  
      return {
        data: response.data,
        status: response.status,
      };


  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};