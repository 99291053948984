import React, { createContext, useContext, useEffect, useState } from "react";
import { Device } from "@capacitor/device";
import { initializeApp } from "firebase/app";
import { getRemoteConfig as getRemoteConfigWeb } from "firebase/remote-config";
import { fetchAndActivate, getAll } from "firebase/remote-config";
import { FirebaseRemoteConfig } from "@capacitor-firebase/remote-config";

interface AuthContextType {
  screenData: any;
}

import { isMobile } from "react-device-detect";

interface AuthContextProp {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

const firebaseConfig = {
  apiKey: "AIzaSyDgwumdff20OvuC4lgZc1-FuiAVuH8LmDc",
  authDomain: "rotobot-ai.firebaseapp.com",
  projectId: "rotobot-ai",
  storageBucket: "rotobot-ai.appspot.com",
  messagingSenderId: "62104652584",
  appId: "1:62104652584:web:f219756c3a769f2aa2de1d",
  measurementId: "G-JHP3WK1GPS",
};

export const FirebaseContextProvider: React.FC<AuthContextProp> = ({
  children,
}) => {
  const [screenData, setScreenData] = React.useState<any>();
  const postFix = process.env.REACT_APP_ENV === "staging" ? "_stg" : "_prod";
  const params = [
    `currentCapabilities${postFix}`,
    `currentCapabilitiesVersion${postFix}`,
    `currentCapabilitiesVersion${postFix}`,
    `defaultSuggestions${postFix}`,
    `playerAnalyzerSuggestions${postFix}`,
    `rankings_last_updated${postFix}`,
  ];
  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfigWeb(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 3000;

  const fetchRemoteParams = async () => {
    fetchAndActivate(remoteConfig).then(() => {
      const allValues = getAll(remoteConfig);
      const filteredValues: any = {};

      Object.entries(allValues).forEach(([key, value]) => {
        const d = JSON.parse(JSON.stringify(value));
        console.log(`+++ ${key}: `, d._value);
        filteredValues[key] = d._value;
      });

      console.log("Remote parameters (Web):", filteredValues);
      setScreenData(filteredValues);
    });
  };

  const fetchRemoteParamsIOS = () => {
    try {
      FirebaseRemoteConfig.fetchAndActivate().then(() => {
        const fetch = async () => {
          const remoteParams: any = {};

          for (const key of params) {
            const value = await FirebaseRemoteConfig.getString({ key });
            remoteParams[key] = value?.value;
          }
          console.log("Remote parameters (Mobile):", remoteParams);
          setScreenData(remoteParams);
        };

        fetch();
      });
    } catch (error) {
      console.log("Error in fetchRemoteParamsIOS =>>", error);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      fetchRemoteParams();
    } else {
      fetchRemoteParamsIOS();
    }
  }, [remoteConfig, FirebaseRemoteConfig]);

  return (
    <AuthContext.Provider
      value={{
        screenData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useFirebaseRemoteParams = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an ApplePayContextProvider");
  }
  return context;
};
