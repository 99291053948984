import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layouts/Layout";
import { Paragraph } from "../Commons/Paragraph";
import { Button } from "../ui/button";
import FeatureCard from "./FeatureCard/FeatureCard";
import FeaturePlayersCard from "./FeaturePlayersCard/FeaturePlayersCard";
import { Colors } from "../Commons/Colors/Colors";
import profileIcon from "../../assets/imgs/profile-default.svg";
import filterIcon from "../../assets/imgs/light-outline-filter.svg";
import cardBg from "../../assets/imgs/home-chat-card-bg.svg";
import chatImg from "../../assets/imgs/home-chat-card-img.svg";
import TradeAnalyzer from "../../assets/imgs/trade-analyzer.svg";
import arrowIcon from "../../assets/imgs/arrow.svg";
import trophyIcon from "../../assets/imgs/trophy.svg";
import premiumIcon from "../../assets/imgs/premium.svg";
import BotIcon from "../../assets/imgs/new-chat-suggestion-icon.svg";
import ArrowTopRight from "../../assets/imgs/arrow_primary_icon.svg";

import {
  getAllPlayerProfileCache,
  playerProfileCountViews,
} from "../../Controllers/PlayerProfileCache";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { capitalizeFirstLetter, hapticsImpactLight } from "../../lib/utils";
import useUserProfile from "../../hooks/useUserProfile";
import { createNewChat } from "../../Controllers/ChatRotobot";
import { useApplePaymentAuth } from "../../Context/AppleContext/AppleContext";
import { isMobile } from "react-device-detect";
import useFirebaseAnalytics from "../../hooks/useFirabaseAnality";
import Capabilities from "../Capabilities/Capabilities";

const HomeComponent = () => {
  const navigate = useNavigate();
  const [players, setPlayers] = useState<any[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [clickedChatNow, setClickedChatNow] = useState<boolean>(
    localStorage.getItem("clickedChatNow") === "true",
  );
  const { getUserProfile, getUserData } = useSupabaseAuth();
  const { firstName, profile } = useUserProfile();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [avatar, setAvatar] = useState<string | undefined | null>(null);
  const [profileSupabase, setProfileSupabase] = useState<any>(null);
  const {
    isActiveSubscription,
    isAppSubscribedMontly,
    isAppSubscribedYearly,
    restore,
    platform,
  } = useApplePaymentAuth();
  // const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const { logEventAnalytics } = useFirebaseAnalytics();
  const [handleCapabilitiesModal, setHandleCapabilitiesModal] = useState(false)

  const handleCreateChat = async () => {
    hapticsImpactLight();
    if (!clickedChatNow) {
      setClickedChatNow(true);
      localStorage.setItem("clickedChatNow", "true");
    } else {
      logEventAnalytics("open_chat_now_home", { name: "Chat with Rotobot" });
      navigate(`/chat/new`);
    }
  };

  const fetchPlayers = async () => {
    const response: any = await getAllPlayerProfileCache();
    if (response.status) {
      const data = response.data?.data;
      if (data) {
        setPlayers((prevPlayers) => {
          const existingPlayerIds = new Set(
            prevPlayers.map((player) => player.id),
          );
          const newPlayers =
            data.items?.filter(
              (player: any) => !existingPlayerIds.has(player.id),
            ) || [];
          return [...prevPlayers, ...newPlayers];
        });
      }
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  useEffect(() => {
    if (isMobile && platform && platform === "ios") {
      restore();
      isActiveSubscription();
    }
  }, [platform]);

  const featureCards = [
    {
      title: "Chatbot Capabilities",
      icon: BotIcon,
      comingSoon: false,
      eventName: "open_capabilities_home",
      isNew: false
    },
    {
      title: "Trade Analyzer",
      icon: TradeAnalyzer,
      link: "/trade-analyzer",
      comingSoon: false,
      eventName: "open_trade_analyzer_home",
      isNew: true
    },
    {
      title: "Sit/Start Tool",
      icon: TradeAnalyzer,
      link: "/sit-start-decider",
      comingSoon: false,
      eventName: "open_sit_start_analyzer_home",
      isNew: true
    },
    {
      title: "Player Rankings",
      link: "/rankings",
      icon: trophyIcon,
      comingSoon: false,
      eventName: "open_rankings_home",
      isNew: false
    },
    {
      title: "League Sync",
      icon: premiumIcon,
      link: "/home",
      comingSoon: true,
      eventName: "open_league_sync_home",
      isNew: false
    },
  ];

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchProfile = async () => {
    if (
      currentUser &&
      (currentUser?.app_metadata?.provider === "phone" ||
        currentUser?.app_metadata?.provider === "apple")
    ) {
      const userProfile: any = await getUserProfile(currentUser?.id);
      setAvatar(userProfile?.avatar_url);
    } else {
      const identity_data = currentUser?.identities?.[0]?.identity_data ?? null;
      setAvatar(identity_data?.avatar_url);
    }

    const response = await getUserProfile(currentUser?.id);
    setProfileSupabase(response);

    // if (isMobile) {
    //   const appleSubcription = JSON.parse(response?.apple_subscription);
    //   const status = await isActiveSubscription(appleSubcription?.id);
    //   setIsSubscribed(status);
    // }
  };

  console.log("currentUser **", currentUser);
  console.log("profileSupabase **", profileSupabase);

  useEffect(() => {
    fetchProfile();
  }, [currentUser]);

  console.log("avatar", avatar);

  const hadleViewProfilePlayer = async (
    playerId: string,
    playerName: string,
  ) => {
    hapticsImpactLight();
    await playerProfileCountViews(playerId);
    logEventAnalytics("open_featured_player_home", {
      playerID: playerId,
      playerName: playerName,
    });
    navigate(`/player-profile/${playerId}`);
  };

  const handleSettings = () => {
    hapticsImpactLight();
    logEventAnalytics("open_settings_home", { name: "Settings" });
    navigate("/settings");
  };

  const handleUserProfile = () => {
    hapticsImpactLight();
    logEventAnalytics("open_profile_home", { name: "View Profile" });
    navigate("/user-profile");
  };

  return (
    <Layout ref={scrollContainerRef}>
      <div className="customBg -m-5 p-5">
        <div className="w-full md:w-[90%] xl:w-[85%] 2xl:w-[80%} m-auto h-full pb-5">
          <div className="flex justify-between sm:items-start items-center">
            <div className="">
              <div className="sm:flex gap-2">
                <Paragraph
                  text="Hey there,"
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                  className=" text-[36px] md:text-[45px] xl:text-[50px] 2xl:text-[60px]"
                />
                <Paragraph
                  text={firstName}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                  className="text-[36px] md:text-[45px] xl:text-[50px] 2xl:text-[60px] font-medium capitalize"
                //className=" md:text-[24px] xl:text-[28px] 2xl:text-[33px] font-bold"
                />
                {/* <div onClick={() => navigate("/user-profile")}>
                  <Paragraph
                    text={"View Profile"}
                    ParagraphType="Paragraph22"
                    color="textPrimary"
                    className="md:text-[18px] xl:text-[20px] 2xl:text-[23.1px] font-medium opacity-70 cursor-pointer"
                  />
                </div> */}
              </div>
              {/* <div
                className="w-[52px] md:w-[60px] xl:w-[65px] 2xl:w-[80px] h-[52px] md:h-[60px] xl:h-[65px] 2xl:h-[80px] border-[2px] rounded-full flex justify-center items-center"
                onClick={() => navigate("/user-profile")}
                style={{ borderColor: Colors.backgroundPrimary }}
              >
                <img
                  src={avatar ? avatar : profileIcon}
                  alt="Profile"
                  className={
                    avatar
                      ? "w-full h-full rounded-full "
                      : "w-[27px] md:w-[30.875px] xl:w-[34.875px] 2xl:w-[36px] object-cover cursor-pointer"
                  }
                />
              </div> */}
              <Paragraph
                text={"Let’s get rolling with your fantasy league!"}
                ParagraphType="Paragraph22"
                color="textPrimary"
                className=" md:text-[22px] xl:text-[26px] 2xl:text-[30px] font-[300] opacity-50 sm:block hidden"
              />
            </div>
            <div className="flex flex-col items-end">
              <div className="flex sm:gap-4 xl:gap-8 2xl:gap-10">
                <div className="sm:block hidden text-right">
                  <Paragraph
                    text={firstName}
                    ParagraphType="Paragraph22"
                    color="textPrimary"
                    className=" md:text-[24px] xl:text-[28px] 2xl:text-[33px] font-bold"
                  />
                  <div onClick={() => {
                    hapticsImpactLight();
                    navigate("/user-profile")}}>
                    <Paragraph
                      text={"View Profile"}
                      ParagraphType="Paragraph22"
                      color="textPrimary"
                      className="md:text-[18px] xl:text-[20px] 2xl:text-[23.1px] font-medium opacity-70 cursor-pointer"
                    />
                  </div>
                </div>
                <div
                  className="w-[52px] md:w-[60px] xl:w-[65px] 2xl:w-[80px] h-[52px] md:h-[60px] xl:h-[65px] 2xl:h-[80px] border-[2px] rounded-full flex justify-center items-center overflow-hidden"
                  onClick={handleUserProfile}
                  style={{ borderColor: Colors.backgroundPrimary }}
                >
                  <div className="w-full h-full" style={{
                    backgroundImage: `url(${avatar ? avatar : profileIcon})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                  }} />
                </div>
              </div>
              <div
                className="w-[52px] md:w-[60px] xl:w-[65px] 2xl:w-[80px] h-[52px] md:h-[60px] xl:h-[65px] 2xl:h-[80px] rounded-full flex justify-center items-center mt-3 cursor-pointer relative z-30 bg-[#ffffff1a] active:bg-[#0048FF]"
                onClick={handleSettings}
              >
                <img
                  src={filterIcon}
                  alt="Filter"
                  className="w-[17.5px] md:w-[20.875px] xl:w-[24.875px] 2xl:w-[28.875px]"
                />
              </div>
            </div>
          </div>

          <div className="flex w-full sm:gap-8 xl:gap-10 2xl:gap-14">
            <div className="flex-1">
              <div
                className="rounded-[10px] md:rounded-[20px] mt-[18px] flex-1 2xl:max-h-[320.45px] max-h-[280.45px]"
                style={{
                  background:
                    "linear-gradient(258deg, #0048FF -8.67%, #060A1F 108.31%)",
                }}
              >
                <div className="flex justify-between rounded-[10px] md:rounded-[20px] relative h-full  2xl:max-h-[320.45px] xl:max-h-[230.45px] home-card-bg">
                  <div className="px-4 md:px-8 xl:px-9 2xl:px-11 py-[18px] md:py-8 xl:py-8 2xl:py-20 w-2/3 relative z-20">
                    <Paragraph
                      text="Chat with RotoBot"
                      ParagraphType="Paragraph22"
                      color="textPrimary"
                      className="text-[20px] md:text-[26.527px] xl:text-[34.527px] 2xl:text-[40.163px] leading-[135%] font-medium tracking-[-0.5px]"
                    />

                    <Paragraph
                      text="Let's team up and conquer your fantasy league!"
                      ParagraphType="Paragraph22"
                      color="textPrimary"
                      className="text-xs md:text-base xl:text-[17px] 2xl:text-[21.038px] leading-[135%] tracking-[-0.5px] opacity-70 sm:w-[50%]"
                    />
                    <Button
                      className="w-[108px] md:w-[150px] xl:w-[190px] 2xl:w-[233px] py-2 md:py-[14.212px] xl:py-[17.212px] px-[14px] text-xs md:text-base xl:text-[18.819px] 2xl:text-[25.819px] mt-3 md:mt-5 bg-[#0045F48C]"
                      onClick={handleCreateChat}
                    >
                      Chat Now{" "}
                      <img
                        src={arrowIcon}
                        alt=""
                        className="w-[18px] md:w-[26px] xl:w-[28.728px] 2xl:w-[38.728px] ml-1"
                      />
                    </Button>
                  </div>

                  <div className="flex-1">
                    <img
                      src={chatImg}
                      alt="Chat"
                      className="absolute -right-4 -bottom-10 md:-bottom-20 xl:-bottom-20 2xl:-bottom-28 w-[206.777px] sm:w-[240px] md:w-[280.876px] lg:w-[424.876px] 2xl:w-[514.876px] z-10"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 px-4 -mx-5 flex md:flex-col gap-[10px] overflow-x-auto no-scrollbar w-screen sm:hidden">
                {featureCards.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (item.title == "Chatbot Capabilities") {
                        setHandleCapabilitiesModal(!handleCapabilitiesModal)
                      } else {
                        if (item.link) {
                          navigate(item.link)
                        }
                      }
                    }}
                  >
                    <FeatureCard
                      imageSrc={item.icon}
                      title={item.title}
                      arrowSrc={arrowIcon}
                      comingSoon={item.comingSoon}
                      eventName={item.eventName}
                      onSentEventFirebase={(
                        name: string,
                        params?: { [key: string]: any },
                      ) => {
                        logEventAnalytics(name, params);
                      }}
                      isNew={item.isNew}
                    />
                  </div>
                ))}
              </div>
              <div className="mt-6">
                <div className="flex justify-between items-center">
                  <Paragraph
                    text="Featured Players"
                    className="text-[18px] md:text-[20px] xl:text-[22px] 2xl:text-[24.3px] leading-[110%] font-semibold"
                    ParagraphType="Paragraph22"
                    color="textPrimary"
                  />
                  <div className="text-xs leading-[110%] text-white" onClick={() => {
                    hapticsImpactLight();
                    navigate("/rankings")
                  }}>View all</div>
                </div>
                <div
                  className="mt-5 gap-3 grid lg:grid-cols-2"
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  {players.map((playerData: any, index: any) => (
                    <div
                      key={index}
                      onClick={() =>
                        hadleViewProfilePlayer(
                          playerData?.playerID,
                          `${playerData?.playerFirstName} ${playerData?.playerLastName}`,
                        )
                      }
                    >
                      <FeaturePlayersCard
                        playerSmallIcon={playerData?.playerheadshotURL}
                        playerName={`${playerData?.playerFirstName} ${playerData?.playerLastName}`}
                        playerPosition={playerData?.playerPosition}
                        playerStatus={
                          playerData?.insightsOverview?.seasonOutlook
                            ?.BlurbTitle
                        }
                        bgColor={playerData?.playerTeamShort}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-[18px] sm:flex sm:flex-col gap-8 overflow-x-auto no-scrollbar hidden ">
              {featureCards.map((item, index) => (
                <FeatureCard
                  key={index}
                  imageSrc={item.icon}
                  title={item.title}
                  arrowSrc={arrowIcon}
                  link={item.link}
                  comingSoon={item.comingSoon}
                  eventName={item.eventName}
                  onSentEventFirebase={(
                    name: string,
                    params?: { [key: string]: any },
                  ) => {
                    logEventAnalytics(name, params);
                  }}
                  isNew={item.isNew}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Capabilities isVisible={handleCapabilitiesModal} onClose={() => {
        hapticsImpactLight();
        setHandleCapabilitiesModal(!handleCapabilitiesModal)
      }} />
    </Layout>
  );
};

export default HomeComponent;
