import React, { useEffect, useState } from "react";
import { Layout } from "../Layouts/Layout";
import { Paragraph } from "../Commons/Paragraph";
import { Colors } from "../Commons/Colors/Colors";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import { useNavigate } from "react-router";
import ToggleBtn from "../Commons/Toggle/Togglebtn";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { hapticsImpactLight } from "../../lib/utils";

const Notifications = () => {
  const navigate = useNavigate();

  const [allowNotifications, setAllowNotifications] = useState(false);
  const [players, setPlayers] = useState([
    { id: "myPlayers", label: "My Players", toggleState: false },
    { id: "allPlayers", label: "All Players", toggleState: false },
  ]);
  const [fantasyAlerts, setFantasyAlerts] = useState([
    { id: "rosterChange", label: "Roster Change", toggleState: false },
    { id: "tradeUpdates", label: "Trade Updates", toggleState: false },
    { id: "waiverAlerts", label: "Waiver Alerts", toggleState: false },
    { id: "weeklyRecap", label: "Weekly Recap", toggleState: false },
    { id: "reminders", label: "Reminders", toggleState: false },
  ]);
  const [gameAlerts, setGameAlerts] = useState([
    { id: "gameStart", label: "Game Start", toggleState: false },
    { id: "Letthegamebegin", label: "Let the game begin", toggleState: false },
    { id: "Commencinggameplay", label: "Commencing gameplay", toggleState: false },
  ]);
  
  const { useAuthStore, getProfileAllowNotifications, setProfileAllowNotifications } =
    useSupabaseAuth();
  const user = useAuthStore((state: any): any => state.user);

  const handleToggle = (setter: any, index: number) => {
    setter((prev: any) => {
      return prev.map((item: any, i: number) => {
        if (i === index) {
          return { ...item, toggleState: !item.toggleState };
        }
        return item;
      });
    });
  };

  const handleAllowNotifications = async (allowNotifications: boolean) => {

    if (user && user?.user?.id) {
      try {
        const response = await setProfileAllowNotifications(
          user?.user?.id as string,
          allowNotifications,
        );
        
      } catch (error) {
        console.log("error updating user profile", error);
      }
    }
    setAllowNotifications(allowNotifications)
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user && user?.user?.id) {
        
        const profile = await getProfileAllowNotifications(user?.user?.id as string);

        if (profile) {
          setAllowNotifications(profile.allow_notifications );          
        }
      }
    };
    fetchUserProfile();
  }, [user]);

  const navigateBack = () => {
    hapticsImpactLight();
    navigate("/settings");
  };

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center grayBtn"
          onClick={navigateBack}
        >
          <img src={ArrowBackIcon} alt="Back" />
        </div>
        <Paragraph
          text="Notifications"
          props={{ fontSize: "28px", fontWeight: 600, marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>

      <div
        className="mt-7 flex justify-between items-center"
        onClick={() => handleAllowNotifications(!allowNotifications)}
      >
        <Paragraph
          text="Allow Notifications"
          props={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "14px",
            letterSpacing: "-0.5px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <ToggleBtn isChecked={allowNotifications} />
      </div>

      {/* <div className="mt-9">
        <Paragraph
          text="Players"
          props={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "14px",
            letterSpacing: "-0.5px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />

        <div
          className="max-w-sm mx-auto text-white rounded-[18px] px-[18px] py-6 mt-4 mb-10 flex flex-col gap-7"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        >
          {players?.map((item, index) => {
            return (
              <div
                className="flex justify-between items-center"
                onClick={() => handleToggle(setPlayers, index)}
                key={index}
              >
                <Paragraph
                  text={item.label}
                  props={{
                    fontSize: "15px",
                    lineHeight: "14px",
                    letterSpacing: "-0.5px",
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <ToggleBtn isChecked={item.toggleState} />
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-9">
        <Paragraph
          text="Fantasy Alerts"
          props={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "14px",
            letterSpacing: "-0.5px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />

        <div
          className="max-w-sm mx-auto text-white rounded-[18px] px-[18px] py-6 mt-4 mb-10 flex flex-col gap-7"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        >
          {fantasyAlerts?.map((item, index) => {
            return (
              <div
                className="flex justify-between items-center"
                onClick={() => handleToggle(setFantasyAlerts, index)}
                key={index}
              >
                <Paragraph
                  text={item.label}
                  props={{
                    fontSize: "15px",
                    lineHeight: "14px",
                    letterSpacing: "-0.5px",
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <ToggleBtn isChecked={item.toggleState} />
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-9">
        <Paragraph
          text="Game Alerts"
          props={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "14px",
            letterSpacing: "-0.5px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />

        <div
          className="max-w-sm mx-auto text-white rounded-[18px] px-[18px] py-6 mt-4 mb-10 flex flex-col gap-7"
          style={{ backgroundColor: Colors.backgroundSecundary }}
        >
          {gameAlerts?.map((item, index) => {
            return (
              <div
                className="flex justify-between items-center"
                onClick={() => handleToggle(setGameAlerts, index)}
                key={index}
              >
                <Paragraph
                  text={item.label}
                  props={{
                    fontSize: "15px",
                    lineHeight: "14px",
                    letterSpacing: "-0.5px",
                  }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
                <ToggleBtn isChecked={item.toggleState} />
              </div>
            );
          })}
        </div>
      </div> */}
    </Layout>
  );
};

export default Notifications;
