import Arrow_trending from "../../assets/imgs/arrow-trending-up.svg";
import Arrow_trending_down from "../../assets/imgs/arrow-trending-down.svg";
import player_icon2 from "../../assets/imgs/stock-img-1.svg";
import player_icon from "../../assets/imgs/stock-img-2.svg";
import { Root } from "@radix-ui/react-slot";

export const headers = [
  {
    headerType: "This Week",
    headers: [
      "Rk.",
      "Player Name",
      "Projected FP",
      "Opponent",
    ],
  },
  {
    headerType: "Rest of Season",
    headers: [
      "Rk.",
      "Player Name",
      "RotoBot Score",
      "Positional Rank",
    ],
  },
];

export const positions = [
  { id: 1, name: "All" },
  { id: 2, name: "QB" },
  { id: 3, name: "RB" },
  { id: 4, name: "WR" },
  { id: 5, name: "TE" },
];

export const playerDummies = [
  {
    arr_img: Arrow_trending,
    roto_player_id: "joshalle1",
    url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/JoshAlle1.webp",
    Name: "J. Allen",
    POS: "QB",
    Team: "BUF",
    rosRank: "QB1",
  },
  {
    arr_img: Arrow_trending_down,
    roto_player_id: "justjeff1",
    url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/JustJeff1.webp",
    Name: "J. Jefferson",
    POS: "WR",
    Team: "MIN",
    rosRank: "WR4",
  },
  {
    arr_img: Arrow_trending,
    roto_player_id: "patrmaho1",
    url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/PatrMaho1.webp",
    Name: "P. Mahomes",
    POS: "QB",
    Team: "KC",
    rosRank: "QB3",
  },
  {
    arr_img: Arrow_trending_down,
    roto_player_id: "georkitt1",
    url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/GeorKitt1.webp",
    Name: "G. Kittle",
    POS: "TE",
    Team: "SF",
    rosRank: "TE4",
  },
];

export const categoriesFilters = [
  // { id: 1, name: "Pre-Draft" },
  // { id: 2, name: "2023 Season" },
  { id: 1, name: "This Week" },
  { id: 2, name: "Rest of Season" },
  // { id: 3, name: "Season Leaders" },
];
