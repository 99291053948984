import React from "react";
import { Layout } from "../Layouts/Layout";
import { Colors } from "../Commons/Colors/Colors";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import { useNavigate } from "react-router-dom";
import { Paragraph } from "../Commons/Paragraph";
import { ChatbotModesCards } from "./data";
import { hapticsImpactLight } from "../../lib/utils";


const ChatbotModes = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="flex items-center justify-between">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center grayBtn"
          onClick={() => {
            hapticsImpactLight();
            navigate("/chat/new")
          }}
        >
          <img src={ArrowBackIcon} alt="" />
        </div>
        <Paragraph
          text="Tips"
          props={{ fontSize: "22px", fontWeight: 500, marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>

      <div className="mt-10 flex flex-col gap-5 pb-5">
        {ChatbotModesCards.map((items, index) => {
          return <div
            className="py-6 px-3 rounded-[18px]"
            style={{
              background: Colors.backgroundSecundary,
            }}
            key={index}
          >
            <img src={items.icons} alt="" className="w-[22.5px] mb-3" />
            <Paragraph
              text={items.title}
              props={{ fontSize: "22.5px", fontWeight: 500 }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
            <Paragraph
              text={items.description}
              props={{ fontSize: "12px", marginTop: "10px" }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
        })}

      </div>
    </Layout>
  );
};

export default ChatbotModes;
