import React, { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../../supabase/supabase.config";
import create from "zustand";
import { persist } from "zustand/middleware";
import { Capacitor } from "@capacitor/core";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { initPushNotifications } from "../../Services/pushNotifications";
import { parseDate } from "../../lib/utils";

interface User {
  email?: string;
  id?: string;
  [key: string]: any;
}

interface AuthContextType {
  signInWithGoogle: () => Promise<boolean | undefined>;
  signInWithApple: () => Promise<void>;
  signout: () => Promise<void>;
  sendSMS: (phoneNumber: string) => Promise<boolean>;
  verifyOTPCode: (phone: string, token: string) => Promise<boolean | undefined>;
  resendOtpCode: (phone: string) => Promise<boolean | undefined>;
  uploadAvatar: (
    file: any,
    userId: string,
  ) => Promise<string | undefined | null>;
  useAuthStore: any;
  user: User | null;
  getUserProfile: (userId: string) => Promise<any | undefined | null>;
  getUserData: () => Promise<any | null | undefined>;
  getProfileForDetails: (userId: string) => Promise<any>;
  getProfileAllowNotifications: (userId: string) => Promise<any>;
  signupProfile: (
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    referralCode: string,
  ) => Promise<any>;
  setProfileAllowNotifications: (
    userId: string,
    allow_notifications: boolean,
  ) => Promise<void>;
  getSessionwitAccessToken: (accessToken: string) => Promise<any>;
  saveAppleSubscription: (userId: string, product: any) => Promise<void>;
  deactivateAccount: (userId: string) => Promise<void>;
}

interface AuthContextProp {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const AuthContextProvider: React.FC<AuthContextProp> = ({
  children,
}) => {
  const [user, setUser] = useState<any>(null);
  // const [profile, setProfile] = useState<any>(null);

  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  const useAuthStore = create<any>(
    persist(
      (set) => ({
        user: null,
        setUser: (user: any) => set({ user }),
        clearUser: () => set({ user: null }),
        profile: null,
        setProfile: (profile: any) => set({ profile }),
        clearProfile: () => set({ profile: null }),
        setAppleProfile: (appleProfile: any) => set({ appleProfile }),
        clearAppleProfile: () => set({ appleProfile: null }),
      }),
      {
        name: "auth-token",
        getStorage: () => localStorage,
      },
    ),
  );

  useEffect(() => {
    const checkSession = async () => {
      console.log("checking Supabase session on load");
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();

      if (error) {
        console.error("error retrieving session:", error);
      } else {
        console.log("supabase session on load:", session);
        if (session) {
          setUser(session.user.user_metadata);
          await useAuthStore.getState().setUser(session);
          getUserProfile(session?.user?.id);
        } else {
          console.log("no session found on load.");
        }
      }
    };

    checkSession();

    if (Capacitor.isNativePlatform()) {
      console.log("google Auth for mobile");
      GoogleAuth.initialize();
    } else {
      console.log("google Auth for web");
      GoogleAuth.initialize({
        clientId:
          "62104652584-b2vvk4ibsj13bsoatna0enkgaj5uct1e.apps.googleusercontent.com",
        scopes: ["profile", "email"],
        grantOfflineAccess: true,
      });
    }

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log("Supabase auth event: ", event, session);
        if (event === "SIGNED_OUT") {
          useAuthStore.getState().clearUser();
          setUser(null);
        } else {
          if (session) {
            setUser(session.user.user_metadata);
            console.log("user data is here", session.user.user_metadata);
            await useAuthStore.getState().setUser(session);
          }
        }
      },
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  async function signInWithGoogle() {
    console.log("signInWithGoogle start");
    try {
      if (Capacitor.isNativePlatform()) {
        console.log("Signing in with Google on mobile");
        const googleUser = await GoogleAuth.signIn();
        console.log("gUser user:", googleUser);

        if (googleUser && googleUser.authentication) {
          console.log(
            "gauth authentication details:",
            googleUser.authentication,
          );

          const nonce = "test";

          const response = await supabase.auth.signInWithIdToken({
            provider: "google",
            token: googleUser.authentication.idToken,
            access_token: googleUser.authentication.accessToken,
            nonce,
          });

          const { data, error } = response;

          if (error) {
            console.error("signing error in with Supabase:", error);
            return false;
          } else {
            console.log("Signed in user:", data.user);
            await useAuthStore.getState().setUser(data);
            await initPushNotifications(data.user?.id);
            return true;
          }
        }
      } else {
        console.log("signing in with google on web");
        const { data, error } = await supabase.auth.signInWithOAuth({
          provider: "google",
        });
        console.log("after auth call -->", data, "or error --> ", error);

        if (error) {
          console.error("erorr signing in with Supabase:", error);
          return false;
        } else {
          console.log("Supabase outh data:", data);
          await useAuthStore.getState().setUser(data);
          // await initPushNotifications(data.)
          return true;
        }
      }
    } catch (error) {
      console.error("Google signin error:", error);
    }
  }

  async function signInWithApple() {
    console.log("signInWithApple start");
    try {
      if (Capacitor.isNativePlatform()) {
        console.log("signInWithApple Native start");
        try {
          const options = {
            clientId: "ai.rotobot.app",
            redirectURI: "https://supa.rotobot.ai/auth/v1/callback",
            scopes: "email name",
          };

          const response = await SignInWithApple.authorize(options);
          console.log("apple sign-in response:", response);

          const supabaseResponse = await supabase.auth.signInWithIdToken({
            provider: "apple",
            token: response.response.identityToken,
          });

          const { data, error } = supabaseResponse;

          if (error) {
            console.error("Error signing in with Supabase:", error);
          } else {
            console.log("Signed in user:", data.user);
            const newUser = {
              givenName: response.response.givenName,
              familyName: response.response.familyName,
              emailApple: response.response.email,
            };

            console.log("newUser --->>>>>:", newUser);
            await useAuthStore.getState().setUser(data.user);
            await useAuthStore.getState().setAppleProfile(newUser);
            await initPushNotifications(data.user?.id);
          }
        } catch (error) {
          console.error("Apple sign-in error:", error);
        }
      } else {
        console.log("signInWithApple web");
        try {
          const { data, error } = await supabase.auth.signInWithOAuth({
            provider: "apple",
          });
          if (!error) {
            await useAuthStore.getState().setUser(data);
            // await initPushNotifications(data)
          }
          console.log(data);
        } catch (error) {
          console.error("Apple sign-in error ", error);
        }
      }
    } catch {
      console.error();
    }
  }

  async function signout() {
    console.log("signout");
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("error on during logout ", error);
    } else {
      useAuthStore.getState().clearUser();
      setUser(null);
      useAuthStore.getState().clearProfile();
      console.log("logout successfully");
    }
  }

  const sendSMS = async (phoneNumber: string) => {
    const { data, error } = await supabase.auth.signInWithOtp({
      phone: phoneNumber,
    });

    if (error) {
      console.log("OTP sent error", error);
      // throw new Error("An error happened during logout");
      return false;
    } else {
      console.log("OTP sent successfully" + data);
      return true;
    }
  };

  const verifyOTPCode = async (phone: string, token: string) => {
    if (token && phone) {
      const { data, error } = await supabase.auth.verifyOtp({
        phone,
        token,
        type: "sms",
      });

      if (error) {
        return false;
      } else {
        setUser(data.session?.user.user_metadata);
        await useAuthStore.getState().setUser(data.session);
        await initPushNotifications(data.user?.id);
        console.log("Verification OTP successfully");
        return true;
      }
    }
  };

  const resendOtpCode = async (phone: string) => {
    const { data, error } = await supabase.auth.resend({
      type: "sms",
      phone: phone,
    });

    if (error) {
      return false;
    } else {
      return true;
    }
  };

  const getUserProfile = async (userId: string) => {
    if (!userId) {
      return null;
    }
    const { data: profileData, error: profileError } = await supabase
      .from("profiles")
      .select("*")
      .eq("user_id", userId)
      .single();

    if (profileData) {
      await useAuthStore.getState().setProfile(profileData);
      return profileData;
    }
    return null;
  };

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event: any, session: any) => {
        console.log("supabase event: ", event);
        if (session == null) {
          console.log("sign_out");
        } else {
          setUser({ ...session.user });
          console.log("User data", session.user.user_metadata);
        }
      },
    );

    // Cleanup subscription
    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  const uploadAvatar = async (file: any, userId: string | undefined) => {
    setUploading(true);

    const fileName = `${userId}_${Date.now()}_${file.name}`;
    const { data, error } = await supabase.storage
      .from("avatars")
      .upload(fileName, file);

    if (error) {
      setError("Error uploading file");
      console.error("Error uploading file:", error);
      setUploading(false);
      return null;
    }

    const response = supabase.storage.from("avatars").getPublicUrl(fileName);

    // if (response?.data.urlError) {
    //   setError("Error getting public URL");
    //   console.error("Error getting public URL:", response?.urlError);
    //   setUploading(false);
    //   return null;
    // }

    const { data: profileData, error: profileError } = await supabase
      .from("profiles")
      .select("*")
      .eq("user_id", userId)
      .single();

    if (profileData) {
      const { error: deleteError } = await supabase.storage
        .from("avatars")
        .remove([profileData.file_bucket]);

      const { data: updateData, error: updateError } = await supabase
        .from("profiles")
        .update({
          avatar_url: response?.data?.publicUrl,
          file_bucket: fileName,
        })
        .eq("id", profileData.id);

      if (updateError) {
        setError("Error updating profile");
        console.error("Error updating profile:", updateError);
        setUploading(false);
        return null;
      }
    } else {
      const { data: updateData, error: updateError } = await supabase
        .from("profiles")
        .insert({
          avatar_url: response?.data?.publicUrl,
          user_id: userId,
          file_bucket: fileName,
        })
        .eq("user_id", userId);
    }

    // if (updateError) {
    //   setError("Error updating profile");
    //   console.error("Error updating profile:", updateError);
    //   setUploading(false);
    //   return null;
    // }

    setAvatarUrl(response?.data?.publicUrl);
    setUploading(false);
    return response?.data?.publicUrl;
  };

  const getUserData = async () => {
    const useStorage = useAuthStore.getState().user;
    const userResponse = await supabase.auth.getUser(useStorage?.access_token);
    console.log("useStorage?.access_token  ", useStorage?.access_token);
    if (userResponse.error) {
      console.error("Error getting user data" + userResponse.error);
      signout();
      window.location.href = "/";
      return null;
    }
    return userResponse;
  };

  const getProfileForDetails = async (userId: string | undefined) => {
    const tag = "getProfileForDetails";
    if (!userId) {
      console.error(`${tag} - userId is undefined`);
      return null;
    }

    const { data, error } = await supabase
      .from("profiles")
      .select("user_email, user_name, user_dob, referral_code, first_name, last_name, feature_credits, is_super_user")
      .eq("user_id", userId)
      .single();

    if (error) {
      console.error(`${tag} - error:`, error);
      return null;
    }
    return data;
  };

  const getProfileAllowNotifications = async (userId: string | undefined) => {
    const tag = "getProfileAllowNotifications";
    if (!userId) {
      console.error(`${tag} - userId is undefined`);
      return null;
    }

    const { data, error } = await supabase
      .from("profiles")
      .select("allow_notifications")
      .eq("user_id", userId)
      .single();

    if (error) {
      console.error(`${tag} - error:`, error);
      return null;
    }
    return data;
  };

  const signupProfile = async (
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    referralCode: string,
  ) => {
    const userProfile = await getUserProfile(userId);

    if (userProfile) {
      const { data, error } = await supabase
        .from("profiles")
        .update({
          user_name: firstName + ' ' + lastName,
          first_name: firstName,
          last_name: lastName,
          user_email: email,
          referral_code: referralCode,
        })
        .eq("user_id", userId);

      if (error) {
        return {
          sucess: false,
          error: error,
        };
      } else {
        return {
          sucess: true,
          data: data,
        };
      }
    } else {
      const { data, error } = await supabase.from("profiles").insert({
        user_id: userId,
        user_name: firstName + ' ' + lastName,
        first_name: firstName,
        last_name: lastName,
        user_email: email,
        referral_code: referralCode,
      });

      if (error) {
        return {
          sucess: false,
          error: error,
        };
      } else {
        return {
          sucess: true,
          data: data,
        };
      }
    }
  };

  const setProfileAllowNotifications = async (
    userId: string,
    allow_notifications: boolean,
  ) => {
    const userProfile = await getUserProfile(userId);

    if (userProfile) {
      const { data, error } = await supabase
        .from("profiles")
        .update({
          allow_notifications: allow_notifications,
        })
        .eq("user_id", userId);
    } else {
      const { data, error } = await supabase.from("profiles").insert({
        user_id: userId,
        allow_notifications: allow_notifications,
      });
    }
  };

  const getSessionwitAccessToken = async (accessToken: string) => {
    const { data, error } = await supabase.auth.getUser(accessToken);

    if (data) {
      return data;
    }
    return null;
  };

  const saveAppleSubscription = async (userId: string, product: any) => {
    const userProfile = await getUserProfile(userId);

    if (userProfile) {
      const { data, error } = await supabase
        .from("profiles")
        .update({
          apple_subscription: product,
        })
        .eq("user_id", userId);

      if (error) {
        console.error("error creating apple subscription", error);
      }
    } else {
      const { data, error } = await supabase.from("profiles").insert({
        user_id: userId,
        apple_subscription: product,
      });

      if (error) {
        console.error("error saving apple subscription", error);
      }
    }
  };

  async function deactivateAccount(userId: string) {
    console.log("deactivateAccount");
    const { error } = await supabase
      .from("profiles")
      .delete()
      .eq("user_id", userId);
    if (error) {
      console.error("error on during delete profile.", error);
    } else {
      const { error } = await supabase.auth.admin.deleteUser(userId);
      if (error) {
        console.error("error on during delete account.", error);
      } else {
        await supabase.auth.signOut();
        await useAuthStore.getState().clearUser();
        setUser(null);
        await useAuthStore.getState().clearProfile();
        console.log("deactivate account successfully");
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signInWithGoogle,
        signInWithApple,
        signout,
        sendSMS,
        verifyOTPCode,
        resendOtpCode,
        uploadAvatar,
        useAuthStore,
        user,
        getUserProfile,
        getUserData,
        getProfileForDetails,
        getProfileAllowNotifications,
        signupProfile,
        setProfileAllowNotifications,
        getSessionwitAccessToken,
        saveAppleSubscription,
        deactivateAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useSupabaseAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return context;
};
