import HttpRequestChat from "../../Services/HttpRequestChat";
import { DataItem } from "./type";

export const getTradeAnalyzer = async (
  players_away: DataItem[],
  players_for: DataItem[],
  user_email: string | null = null,
  platform: string | null | undefined,
) => {
  try {
    const response = await HttpRequestChat({
      method: "POST",
      url: "/v1/trade_analyzer?platform=" + platform,
      data: {
        players_away,
        players_for,
        user_email: user_email,
      },
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error: any) {
    return {
      status: error?.status,
      errors: error?.error,
    };
  }
};
