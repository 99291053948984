import React from 'react';
import { Paragraph } from '../../Commons/Paragraph';
import InfoIcon from "../../../assets/imgs/info-icon.svg";
import ArrowUp from "../../../assets/imgs/arrow-green-up.svg";
import ArrowDown from "../../../assets/imgs/arrow-red-down.svg";
import CircularProgressBar from '../../Commons/CircularProgressBar/CircularProgressBar';
import LineChart from '../../Commons/lineChart/lineChart';

interface PerformanceScoreTrendProps {
  percentageChange: number;
  weeklyPerformanceScores: Record<string, number>;
  percentage: number;
  circularBarColor: string;
  handlePerfomanceModal?: any
}

const PerfomanceScoreTrand: React.FC<PerformanceScoreTrendProps> = ({
  percentageChange,
  weeklyPerformanceScores,
  percentage,
  circularBarColor, handlePerfomanceModal
}) => {

  const labels = Object.keys(weeklyPerformanceScores).map(key => `Wk ${key}`);
  const chartdata = Object.values(weeklyPerformanceScores);

  // const lineColor: string = chartdata[0] > chartdata[chartdata.length - 1] ? "#F11E84" : "#16C265";

  return (
    <div className="px-1">
      <div className="flex items-center justify-between mb-4">
        <Paragraph
          text={"Performance Score Trend"}
          className="text-[19.25px] !font-semibold"
          ParagraphType="Paragraph22"
          color="textPrimary"
        />
        <div className=" cursor-pointer" onClick={handlePerfomanceModal}>
          <img src={InfoIcon} alt='' />
        </div>
      </div>

      <div className="flex justify-between items-end mb-8">
        <div>
          <CircularProgressBar
            percentage={percentage}
            pathColor={circularBarColor}
            className='w-[68px]'
          />
          <Paragraph
            text={"Overall"}
            className="text-[20.002px] mt-4"
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
        </div>
        <div>
          <Paragraph
            text={`${chartdata[chartdata.length - 1]?.toFixed(1) ?? 0}`}
            className="text-[20.002px] !font-bold"
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
          <Paragraph
            text={"Last Week"}
            className="text-[10px] mt-4 opacity-90 mb-1"
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
        </div>
        <div>
          <div className="flex gap-2">
            <Paragraph
              text={`${Math.abs(percentageChange)?.toFixed(1) ?? 0}%`}
              className="text-[20.002px] !font-bold"
              ParagraphType="Paragraph22"
              color="textPrimary"
            />
            <img src={percentageChange > 0 ? ArrowUp : ArrowDown} alt='' />
          </div>
          <Paragraph
            text={"Change"}
            className="text-[10px] mt-4 opacity-90 mb-1"
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
        </div>
      </div>
      <div className="mb-7">
        <LineChart
          chartdata={chartdata}
          labels={labels}
          color={"#3EC4FF"}
        />
      </div>
    </div>
  );
}

export default PerfomanceScoreTrand;
