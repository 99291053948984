import httpRequest from "./HttpClient";

interface HttpRequestOptions {
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  url: string;
  headers?: { [key: string]: string };
  params?: { [key: string]: string };
  data?: any;
}

const httpRequestChat = async (options: HttpRequestOptions): Promise<any> => {
  try {
    return await httpRequest(options);
  } catch (error: any) {
    const errorCode = error.status || "Unknown Error Code";
    const errorData = error.detail || error;
    return Promise.reject({
      status: errorCode,
      error: errorData,
    });
  }
};

export default httpRequestChat;
