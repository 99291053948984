import React, { useState, useEffect } from 'react';

interface Player {
  playerTeamImageURL: string;
}

interface Props {
  player: Player;
  playerId: string;
}

const DynamicOpacityBackground: React.FC<Props> = ({ player, playerId }) => {
  const [imageBrightness, setImageBrightness] = useState<number | null>(null);

  useEffect(() => {
    if (player?.playerTeamImageURL) {
      calculateBrightness(player.playerTeamImageURL).then((brightness) => {
        setImageBrightness(brightness);
      });
    }
  }, [player?.playerTeamImageURL]);

  const calculateBrightness = (imageUrl: string): Promise<number> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageUrl;
      image.crossOrigin = 'Anonymous';

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          reject('Could not get 2D context from canvas');
          return;
        }

        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0, image.width, image.height);

        const imageData = context.getImageData(0, 0, image.width, image.height);
        const data = imageData.data;

        let totalBrightness = 0;

        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];    
          const g = data[i + 1]; 
          const b = data[i + 2];

          const avg = (r + g + b) / 3;
          totalBrightness += avg;
        }

        const brightness = totalBrightness / (image.width * image.height);
        resolve(brightness);
      };

      image.onerror = (err) => reject('Failed to load image');
    });
  };

  const calculatedOpacity = imageBrightness !== null
    ? Math.min(Math.max(imageBrightness / 255, 0.1), 0.9)
    : 0.01;       
    
  return (
    <div
      className="absolute -top-10 w-full h-full sm:hidden flex  items-center"
      style={{
        opacity: calculatedOpacity > 0.1 ? 0.1 : 0.3,
      }}
    >
        <img src={player?.playerTeamImageURL} alt='' className={` object-cover ${playerId === "danijone1" ?'w-[65%] h-[80%]': "w-full"}`}/>
    </div>
  );
};

export default DynamicOpacityBackground;
