import { Colors } from "../../Commons/Colors/Colors";
export const HeaderStyle = {
    icon: {
      borderRadius: "100px",
      width:"58px",
      height:"58px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    },
};